import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useTypographyStyles, useLayoutStyles } from '../styles';

import VideoBox from "../components/VideoBox"; 

const useStyles = makeStyles(theme => ({
  header: {
    height: '100px',
    // [theme.breakpoints.up(600)]: {
    //   height: '100px',
    // },
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    margin: '25px 10px',
  },
  textHeader: {
    textAlign: 'center',
    color: '#53595d',
  },
  paragraph: {
    textAlign: 'justify',
    color: '#7f7f7f'
  },
  stackContainer: {
    maxWidth: 1400,
    margin: theme.spacing(2), 
    marginTop: theme.spacing(1), 
    [theme.breakpoints.up(800)]: {
      margin: theme.spacing(3), 
      marginTop: theme.spacing(1),
    },
  },
  videoBox: {
    backgroundColor: '#f6f6f6',
    padding: theme.spacing(3),
    [theme.breakpoints.up(900)]: {
      width: "100%",
    },
  },
}));


export default function Media() {
  const classes = useStyles();

  const layoutClasses = useLayoutStyles();
  const typogClasses = useTypographyStyles();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(600));

  return (
    <>
      <Box className={classes.header}>
        <div className={typogClasses.pageTitle}>
          <p>3DMorphic in the <b>Media</b></p>
        </div>
      </Box>
      <br />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 4 }} className={classes.stackContainer}>
        <Box className={classes.videoBox}>
          <VideoBox videoId="F6pfJONWK7k" />
          <Typography variant="h5" className={classes.textHeader} sx={{ my: 2, textAlign: 'left' }}>
            <b>3DMorphic Custom Spinal Cage for DDD Patient (2017)</b>
          </Typography>
          <Typography variant="body1" className={classes.paragraph} sx={{ mt: 2, textAlign: 'justify' }}>
            This 9News story features a 3DMorphic custom-made ALIF spinal implant, designed and manufactured for patient with Degenerative Disc Disease (DDD). The operation was successful, providing long term pain relief for the patient. 
          </Typography>
        </Box>
        <Box className={classes.videoBox}>
          <VideoBox videoId="T5bqcFTTtpk" />
          <Typography variant="h5" className={classes.textHeader} sx={{ my: 2, textAlign: 'left' }}>
            <b>3DMorphic Custom Cage for Cancer Patient (2018)</b>
          </Typography>
          <Typography variant="body1" className={classes.paragraph} sx={{ mt: 2, textAlign: 'justify' }}>
            This 7News story features a 3DMorphic custom-made cervical corpectomy spinal implant, designed and manufactured in 2018 for patient with bone cancer. Three vertebral bodies were removed and replaced with a 3DMorphic cage. As seen in the video, the patient experienced a remarkable recovery.
          </Typography>
        </Box>
        <Box className={classes.videoBox}>
          <VideoBox videoId="HhMqIxU6aQg" />
          <Typography variant="h5" className={classes.textHeader} sx={{ my: 2, textAlign: 'left' }}>
            <b>3DMorphic Custom Cage for Trauma Patient (2021)</b>
          </Typography>
          <Typography variant="body1" className={classes.paragraph} sx={{ mt: 2, textAlign: 'justify' }}>
            This 7News story features a 3DMorphic custom-made cervical spinal implant, designed and manufactured in 2021 for an emergency trauma patient. This was a landmark case being the first patient specific spinal trauma device. The case demonstrated that 3DMorphic’s process is fast enough to achieve the necessary turnaround, something thought impossible a few years ago. 
          </Typography>
        </Box>
      </Stack>
    </Box>

    </>
  );
};
