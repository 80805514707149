import { makeStyles } from '@material-ui/core/styles';
import colours from './colours';

const useTypographyStyles = makeStyles((theme) => ({
  root: {
    fontFamily: '"DM Sans", sans-serif',
  },
  pageTitle: {
    fontSize: '3.75rem',
    fontWeight: 400,
    color: colours.textDarkGrey,
    textAlign: 'center',
    [theme.breakpoints.down(960)]: {
      fontSize: '3rem',
    },
  },
  pageInfo: {
    fontSize: '1.5rem',
    fontWeight: 400,
    // textAlign:  'justify',
    lineHeight: 1.5,
    margin: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
      fontSize: '1.2rem',
      margin: 0,
    },
  },


  greyText: {
    color: colours.textDarkGrey,
  },
  whiteText: {
    color: colours.textWhite,
  },

  paragraphBig: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.5,
    margin: theme.spacing(2),

    [theme.breakpoints.down(600)]: {
      fontSize: '1.1rem',
      margin: theme.spacing(0, 1),
    },
  },

  paragraphSmall: {
    fontSize: '1.4rem',
    fontWeight: 400,
    color: colours.textDarkGrey,
    lineHeight: 1.5,
    margin: theme.spacing(2),

    [theme.breakpoints.down(600)]: {
      fontSize: '1rem',
      margin: theme.spacing(1, 0),
    },
  },

  textJustifyCentre: {
    textAlign:  'justify',

    [theme.breakpoints.down(600)]: {
      textAlign: 'center',
    },
  },

  textLeftCentre: {
    textAlign:  'left',

    [theme.breakpoints.down(600)]: {
      textAlign: 'center',
    },
  },

  textJustify: {
    textAlign:  'justify',
  },
  textCentre: {
    textAlign:  'center',
  },

  subHeading: {
    fontSize: '2.3rem',
    margin: theme.spacing(2, 0),
    letterSpacing: '2px',
    lineHeight: 1.5,
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.9rem',
      margin: theme.spacing(2, 2),
    },
    [theme.breakpoints.up(1100)]: {
      fontSize: '3.3rem',
    },
  },
  
  strongSubHeading: {
    fontSize: '2.5rem',
    fontWeight: 500,
    lineHeight: 1.5,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      fontSize: '3.1rem',
      margin: theme.spacing(2, 2),
    },
    [theme.breakpoints.up(1100)]: {
      fontSize: '3.5rem',
    },
  },

  subSubHeading: {
    fontSize: '1.7rem',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.1rem',
      margin: theme.spacing(2, 2),
    },
  },

  // #########################################
  // PRODUCTS PAGE SPECIFIC
  // #########################################
  dotPoint: {
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: theme.spacing(1),
    flexShrink: 0,
    minWidth: '15px',
    marginTop: '9px',
    background:"#FFC000"
  },
  DotPointInfo: {
    fontSize: '1.3rem',
    fontWeight: 400,
    color: colours.textGrey,
    textAlign:  'justify',
    lineHeight: 1.5,
    margin: theme.spacing(2, 3),
  },

  boldLink: {
    fontWeight: 'bold',
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
  },

  productFrameTitle: {
    fontSize: '2.4rem',
    fontWeight: 500,
    color: colours.textWhite,
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.9rem',
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: '1.9rem',
  },
  },

  ProductRichTextTitle: {
    fontSize: '2.5rem',
    fontWeight: 500,
    color: colours.textDarkGrey,
    lineHeight: 1.5,
    textAlign: 'left',
    marginLeft: theme.spacing(2),
  },

  productCustomTitle: {
    fontSize: '3rem',
    fontWeight: 400,
    color: colours.MediumDarkerGrey,
    textAlign: 'center',
    marginTop: theme.spacing(12),
  },

  additionalText: {
    fontSize: '2rem',
    fontWeight: '500',
    color: colours.FrameHoverGrey,
    letterSpacing: '0.2em',
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },


}));

export default useTypographyStyles;
