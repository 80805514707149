import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useMediaQuery } from "@mui/material";
import GenericBtn from "../components/GenericBtn";
import { useNavigate } from "react-router-dom";
import { Navigation, Pagination, A11y, Loop, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { useTypographyStyles, useLayoutStyles } from '../styles';

// Define the styles
const useStyles = makeStyles(theme => ({
  scrollProductsTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  scrollProductsParagraph: {
    textAlign: 'center',
    fontSize: '0.75rem',
    margin: theme.spacing(0, 0.5),
    marginBottom: 25,
    [theme.breakpoints.up(620)]: {
      textAlign: 'justify',
      fontSize: '1.4rem',
      margin: theme.spacing(0, 2),
    },
  },

  swipeDisplayCage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
    marginRight: 20,
    [theme.breakpoints.down(600)]: {
      marginRight: 5,
      width: '40%',
    },
  },

  swipeDisplayCageLeft: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
    marginLeft: 20,
    [theme.breakpoints.down(600)]: {
      marginLeft: 5,
      width: '40%',
    },
  },

  swipeTitle: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up(600)]: {
      fontSize: '1.7rem',
      margin: theme.spacing(2, 2),
    },
    [theme.breakpoints.up(960)]: {
      fontSize: '2rem',
      margin: theme.spacing(2, 2),
    },
  },

  swipeCageLabel: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up(600)]: {
      fontSize: '1.4rem',
      margin: theme.spacing(2, 2),
    },
    [theme.breakpoints.up(960)]: {
      fontSize: '1.7rem',
      margin: theme.spacing(2, 2),
    },
  },

}));

/**
 * Landing page
 * @returns
 */
export default function Home() { 
  const classes = useStyles();

  const layoutClasses = useLayoutStyles();
  const typogClasses = useTypographyStyles();

  const navigate = useNavigate();
  const handleNavClick = (route) => {
    navigate(route);
  };

  const show_cage_swiper = useMediaQuery("(min-width:800px)");

  const alignmentVideoRef = useRef(null);
  const handleVideoEnd = () => {
    setTimeout(() => {
      alignmentVideoRef.current.play();
    }, 5000);
  };

  return (
  <>    
    <div className={layoutClasses.lightgreyContainer}>
      <div className={layoutClasses.sectionContainer}>
        <div className={`${typogClasses.pageTitle} ${typogClasses.greyText} ${layoutClasses.TitleInfoContainer} ${layoutClasses.topBottomPadding}`}>
          Patient Specific <b>Implants</b>
        </div>
        {/* <div className={typogClasses.pageTitle}>
          <p>Patient Specific <b>Implants</b></p>
        </div> */}
      </div>
    </div>
    
    <div className={`${layoutClasses.splitContainer} ${layoutClasses.centreItem}`}>
      <div className={`${typogClasses.subHeading} ${typogClasses.greyText} ${typogClasses.textCentre}`} style={{ letterSpacing: '2px' }}>
          Unique <b>needs</b> require<br/> Unique <b>solutions</b>
      </div>
      <div className={layoutClasses.rightVideoContainer}>
        {/* Video replacing placeholder */}
        <video
          ref={alignmentVideoRef}
          src="https://d3fcjysp85tqc8.cloudfront.net/homevid1.mp4" 
          alt="Video Content"
          className={layoutClasses.videoPlaceholder}
          autoPlay
          muted
          style={{ width: '40%' }}
          onEnded={handleVideoEnd}
          controls={false}
          playsInline
        >
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
      
    <div className={layoutClasses.yellowContainer} style={{ paddingTop: '5%', paddingBottom: '5%' }} >
      <div className={layoutClasses.sectionContainer}>
        <div className={`${typogClasses.subHeading} ${typogClasses.greyText} ${typogClasses.textCentre}`}>Our <b>Mission</b></div>
        <div className={`${typogClasses.paragraphBig} ${typogClasses.greyText} ${typogClasses.textJustifyCentre}`}>
          Our mission is to improve spinal surgery outcomes and reduce revision rates using our patient specific 
          spinal implants. To achieve this, 3DMorphic produces quality medical devices that are designed specifically 
          for the patient's anatomy, with in house manufacturing via state-of-the-art 3D printing. Through our 
          patient specific devices, we aim to improve the quality of life for spinal patients, whilst reshaping the 
          spinal medical device industry.
        </div>
        <div 
          className={layoutClasses.centreItem} 
          style={{ marginTop: 40 }}
        >
          <GenericBtn
            color='#53595D' 
            backgroundColor="#ffc103" 
            fontSize="16px" 
            font="'Roboto', sans-serif"
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => handleNavClick("/Difference")}
          >
            Learn about us
          </GenericBtn>
        </div>
      </div>
    </div>

    <div className={layoutClasses.sectionContainer}>
      <div className={`${typogClasses.subHeading} ${typogClasses.greyText} ${typogClasses.textCentre}`}>Our <b>Solution</b></div>
      <div className={layoutClasses.splitContainer}>
        <div className={`${layoutClasses.column} ${layoutClasses.flex1}`}>
          <div className={`${typogClasses.subSubHeading} ${typogClasses.greyText} ${typogClasses.textLeftCentre}`}>
            <b>The 3DMorphic Approach</b>
          </div>
          <div className={`${typogClasses.paragraphSmall} ${typogClasses.greyText} ${typogClasses.textJustifyCentre}`}>
            Every patient and every surgery is unique, and as such, we believe customisation is key. 
            Our implants are crafted to match the unique anatomy of each patient. This provides immediate 
            stabilisation while also facilitating fusion, which reduces the risk of subsidence.
          </div>
        </div>
        <div className={`${layoutClasses.column} ${layoutClasses.flex1}`}>
          <div className={`${typogClasses.subSubHeading} ${typogClasses.greyText} ${typogClasses.textLeftCentre}`}>
            <b>Why Patient Specific?</b>
          </div>
          <div className={`${typogClasses.paragraphSmall} ${typogClasses.greyText} ${typogClasses.textJustifyCentre}`}>
            Patient specific devices are designed to fit better, providing a more even load distribution 
            between the implant and patient anatomy. The increase bone-device contact surface area promotes 
            bone on-growth. When combined with integral screw fixation, this results in a more stable 
            construct, which can result in better patient quality of life.
          </div>
        </div>
      </div>

      {/* this will be the verts coming from above and below onto the cage */}
      <div className={layoutClasses.centreItem}>
        <div className={layoutClasses.width_90_50}>
          <video 
            src="https://d3fcjysp85tqc8.cloudfront.net/Distractionvid.mp4" 
            alt="Video Placeholder"
            className={layoutClasses.videoPlaceholder}
            loop
            autoPlay
            muted
            style={{ height: '50%' }}
            controls={false}
            playsInline
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
    <div className={layoutClasses.greyContainer}>
      <div className={layoutClasses.sectionContainer} style={{maxWidth: '1200px'}}>
        <div className={`${typogClasses.subHeading} ${typogClasses.whiteText} ${typogClasses.textCentre}`}>Our <b>Products</b></div>
        <div className={layoutClasses.splitContainer} style={{ alignItems: 'center', marginBottom: 30 }}>
          {/* <div className={classes.centreItem}> */}
            <img 
              src="https://d3fcjysp85tqc8.cloudfront.net/artgl.webp" 
              alt="Video Placeholder"
              className={layoutClasses.artgLogo}
              />
            {/* </div> */}
          <div className={`${typogClasses.paragraphBig} ${typogClasses.whiteText} ${typogClasses.textJustifyCentre}`}>
            3DMorphic is Australia's first ARTG listed manufacturer of patient specific spinal implants, and is 
            dedicated to producing personalised surgical solutions that improve patient quality of life through 
            in-house design and manufacturing. 
          </div>
        </div>
        <style>
          {`
            .swiper-button-next, .swiper-button-prev {
                color: white;
                margin:-10px;
                @media (max-width: 800px) {
                  display: none;
                }
            }

            /* If the arrows are SVGs, this will change their fill color to white */
            .swiper-button-next svg, .swiper-button-prev svg {
                fill: white;
            }
            
            /* Styling for all pagination dots */
            .swiper-pagination-bullet {
                background-color: rgba(255, 255, 255, 0.5); /* Non-active dots (change color as needed) */
            }
      
            /* Styling for the active pagination dot */
            .swiper-pagination-bullet-active {
                background-color: white; /* Active dot */
            }
          `}
        </style>
        <Swiper
          modules={[Navigation, Pagination, A11y, Autoplay]}
          // spaceBetween={50}
          // slidesPerView={1}
          loop={true}
          navigation
          pagination={{ clickable: true }}
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide className={layoutClasses.centreItem}>
            <div className={layoutClasses.swiperProductsContainer}>
              <img 
                src="https://d3fcjysp85tqc8.cloudfront.net/1.webp"
                alt="ALIF in Spine"
                style={{width: '30%'}}
              />
              <div className={`${layoutClasses.centreItem} ${layoutClasses.column} ${layoutClasses.paddRight}`}>
                <div className={`${classes.swipeTitle} ${typogClasses.whiteText}`}>
                  <a style={{color: "#FFC000", textAlign: 'left'}}> ALIF </a> DEVICES
                </div>
                <div className={`${classes.scrollProductsParagraph} ${typogClasses.whiteText}`}>
                  Anterior Lumbar Interbody Fusion (ALIF) devices are cages implanted in the lower 
                  spine using an anterior surgical approach.
                </div>
              </div>
              { show_cage_swiper &&
                <div className={`${classes.swipeDisplayCage} ${layoutClasses.column}`}>
                  <img 
                    src="https://d3fcjysp85tqc8.cloudfront.net/Device1.webp"
                    alt="ALIF alone"
                    width="100%"
                  />
                  <div className={`${classes.swipeCageLabel} ${typogClasses.whiteText}`}>ALIF</div>
                </div>
              }
            </div>
          </SwiperSlide>
          <SwiperSlide className={layoutClasses.centreItem}>
            <div className={layoutClasses.swiperProductsContainer}>
              <img 
                src="https://d3fcjysp85tqc8.cloudfront.net/2.webp"
                alt="ACDF in Spine"
                style={{width: '30%'}}
              />
              <div className={`${layoutClasses.centreItem} ${layoutClasses.column} ${layoutClasses.paddRight}`}>
                <div className={`${classes.swipeTitle} ${typogClasses.whiteText}`}>
                  <a style={{color: "#FFC000"}}> ACDF </a> DEVICES
                </div>
                <div className={`${classes.scrollProductsParagraph} ${typogClasses.whiteText}`}>
                  Anterior Cervical Discectomy and Fusion (ACDF) devices are cages implanted in the 
                  upper spine and neck, inserted using an anterior open surgical approach.
                </div>
              </div>
              { show_cage_swiper &&
                <div className={`${classes.swipeDisplayCage} ${classes.scrollProductsTextWrapper}`}>
                  <img 
                    src="https://d3fcjysp85tqc8.cloudfront.net/Device2.webp" 
                    alt="ACDF alone"
                    width="75%"
                  />
                  <div className={`${classes.swipeCageLabel} ${typogClasses.whiteText}`}>ACDF</div>
                </div>
              }
            </div>
          </SwiperSlide>
          <SwiperSlide className={layoutClasses.centreItem}>
            <div className={layoutClasses.swiperProductsContainer}>
              { show_cage_swiper &&
                <div className={`${classes.swipeDisplayCageLeft} ${classes.scrollProductsTextWrapper}`}>
                  <img 
                    src="https://d3fcjysp85tqc8.cloudfront.net/Device3.webp" 
                    alt="Corpectomy alone"
                    style={{height: '50%', width: '50%'}}
                  />
                  <div className={`${classes.swipeCageLabel} ${typogClasses.whiteText}`}>CERVICAL CORPECTOMY</div>
                </div>
              }
              <div className={`${layoutClasses.centreItem} ${layoutClasses.column} ${layoutClasses.paddLeft}`}>
                <div className={`${classes.swipeTitle} ${typogClasses.whiteText}`}>
                  <a style={{color: "#FFC000"}}>CORPECTOMY</a> DEVICES
                </div>
                <div className={`${classes.scrollProductsParagraph} ${typogClasses.whiteText}`}>
                  A corpectomy device is a cage designed to replace the entire vertebral body and the 
                  adjoining discs. 3DMorphic produces both lumbar and cervical corpectomy cages.
                </div>
              </div>
              <img 
                src="https://d3fcjysp85tqc8.cloudfront.net/3.webp" 
                alt="Corpectomy in Spine"
                style={{width: '30%'}}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className={layoutClasses.centreItem}>
            <div className={layoutClasses.swiperProductsContainer}>
             { show_cage_swiper &&
                <div className={`${classes.swipeDisplayCageLeft} ${classes.scrollProductsTextWrapper}`}>
                  <img 
                    src="https://d3fcjysp85tqc8.cloudfront.net/Device4.webp" 
                    alt="LLIF alone"
                    style={{width: '100%'}}
                  />
                  <div className={`${classes.swipeCageLabel} ${typogClasses.whiteText}`}>LATERAL LIF</div>
                </div>
              }
              <div className={`${layoutClasses.centreItem} ${layoutClasses.column} ${layoutClasses.paddLeft}`}>
                <div className={`${classes.swipeTitle} ${typogClasses.whiteText}`}>
                  <a style={{color: "#FFC000"}}>LATERAL LIF</a> DEVICES
                </div>
                <div className={`${classes.scrollProductsParagraph} ${typogClasses.whiteText}`}>
                  Lateral Lumbar Interbody Fusion devices are cages implanted in the lower anterior 
                  column using a lateral open surgical approach.
                </div>
              </div>
              <img 
                src="https://d3fcjysp85tqc8.cloudfront.net/4.webp" 
                alt="LLIF in Spine"
                style={{width: '30%'}}
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </>
  );
}


