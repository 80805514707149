import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Products from "./pages/Products"; 
import Home from "./pages/Home";
import Who from "./pages/Who";
import Media from "./pages/Media";
import Services from "./pages/Services";
import Difference from "./pages/Difference";
import Contact from "./pages/Contact";
import Header from "./components/Header"; 
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="background">
      <Router>
          <Header />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />

            {/* Authorized routes */}
            <Route path="/Products" element={<Products />} />
            <Route path="/Who" element={<Who />} />
            <Route path="/Difference" element={<Difference />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Media" element={<Media />} />
            <Route path="/Contact-us" element={<Contact />} />
          </Routes>
          <Footer />
      </Router>
    </div>
  );
}

export default App;
