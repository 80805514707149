import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
  
// importing material UI components
import { makeStyles, AppBar, Toolbar, Drawer } from "@material-ui/core";
import { Stack, Box, useMediaQuery } from "@mui/material";

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import NavBtn from "./NavBtn"; 



const useStyles = makeStyles((theme) => ({
  appBar: {
    paddingTop: 15,
    paddingBottom: 10,
    boxShadow: 'none',
    position: 'sticky',
    top: 0,
    transition: 'transform 0.5s ease',
    backgroundColor: 'rgba(246, 246, 246, 0.95)'
  },
  toolbar: {
    maxWidth: '1300px',
    margin: 'auto',
    padding: '0px 30px',
  },

  globalContainer: {
    maxWidth: '1200px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 20, // Adjust this for padding on the left
    paddingRight: 20, // Adjust this for padding on the right
  },
  logo: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  logoImage: {
    height: 70,
    marginRight: theme.spacing(2),
    cursor:"pointer",
    [theme.breakpoints.up(900)]: {
      height: 80,
    },
    [theme.breakpoints.up(1400)]: {
      height: 92,
    },
  },
  appBarHidden: {
    transform: 'translateY(-100%)',
  },
  button: {
    fontSize: 12,
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const activeRoute = location.pathname;

  const [scrollPosition, setScrollPosition] = useState(0);
  const [lastUpScrollPos, setLastUpScrollPos] = useState(0);
  const [headerVisibility, setHeaderVisibility] = useState(true);

  const mediumViewport = useMediaQuery("(min-width:920px)");

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth > 900) {
        setHeaderVisibility(true); // Keep the header always visible
      } else {
        const currentScrollPos = window.scrollY;
        const headerVisibility = 
          currentScrollPos < scrollPosition ||              // scrolling up
          (currentScrollPos - lastUpScrollPos) <= 100 ||    // scrolled less than 50px from turn around
          currentScrollPos <= 150;                          // top of the page

        if (currentScrollPos < scrollPosition) {
          setLastUpScrollPos(currentScrollPos);
        }

        setHeaderVisibility(headerVisibility);
        setScrollPosition(currentScrollPos);
      }
    };
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    }
  }, [scrollPosition]);

  

  const handleMenuClicked = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const navDrawerClick = (route) => {
    setSideBarOpen(false);
    navigate(route)
  };

  return (
    <AppBar className={`${classes.appBar} ${!headerVisibility && classes.appBarHidden}`}>
      <div>
      <Toolbar className={classes.toolbar}>
        <div className={classes.logo} onClick={() => navDrawerClick("/")}>
          {/* <img src="https://d3fcjysp85tqc8.cloudfront.net/3dmorphic_logo.webp" alt="Logo" className={classes.logoImage} /> */}
          {/* <img src="https://d3fcjysp85tqc8.cloudfront.net/3DM_Logo_with_R_words_below-removebg.webp" alt="Logo" className={classes.logoImage} /> */}
          {/* <img src="https://d3fcjysp85tqc8.cloudfront.net/3DM_logo_R_symbol.webp" alt="Logo" className={classes.logoImage} /> */}
          <img src="https://d3fcjysp85tqc8.cloudfront.net/Logo.svg" alt="Logo" className={classes.logoImage} />
        </div>
        { mediumViewport ? 
          <Stack spacing={2} direction="row" sx={{mt:2}}>
            <NavBtn onClick={() => navDrawerClick("/"             )} isActive={activeRoute === "/"      } >Home </NavBtn>
            <NavBtn onClick={() => navDrawerClick("/Difference"   )} isActive={activeRoute === "/Difference"} >Difference </NavBtn>
            <NavBtn onClick={() => navDrawerClick("/Products"     )} isActive={activeRoute === "/Products"  } >Products</NavBtn>
            <NavBtn onClick={() => navDrawerClick("/Services"     )} isActive={activeRoute === "/Services"  } >Services</NavBtn>
            <NavBtn onClick={() => navDrawerClick("/Media"        )} isActive={activeRoute === "/Media"     } >Media</NavBtn>
            <NavBtn onClick={() => navDrawerClick("/Contact-us"   )} isActive={activeRoute === "/Contact-us"} >Contact Us</NavBtn>
          </Stack>
          :
          <MenuRoundedIcon onClick={handleMenuClicked} style={{ cursor:"pointer", color: '#464946', fontSize: 40, marginTop: 10, marginRight: 10 }}/>
        }
        <Drawer open={sideBarOpen} anchor="right" onClose={handleMenuClicked}>
          <Box sx={{ width: {xs: "70vw", sm:"55vw"} }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CloseRoundedIcon onClick={handleMenuClicked} sx={{ cursor:"pointer", m: 2, fontSize: 40 }} />
            </Box>
            <Stack sx={{ width: 270, mx:"auto", mt: 10 }} spacing={3} direction="column">
              <NavBtn onClick={() => navDrawerClick("/"             )} isActive={activeRoute === "/"      } >Home </NavBtn>
              <NavBtn onClick={() => navDrawerClick("/Difference"   )} isActive={activeRoute === "/Difference"}>Difference</NavBtn>
              <NavBtn onClick={() => navDrawerClick("/Products"     )} isActive={activeRoute === "/Products"  }>Products</NavBtn>
              <NavBtn onClick={() => navDrawerClick("/Services"     )} isActive={activeRoute === "/Services"  }>Services</NavBtn>
              <NavBtn onClick={() => navDrawerClick("/Media"        )} isActive={activeRoute === "/Media"     }>Media</NavBtn>
              <NavBtn onClick={() => navDrawerClick("/Contact-us"   )} isActive={activeRoute === "/Contact-us"}>Contact Us</NavBtn>
            </Stack>
          </Box>
        </Drawer>
      </Toolbar>
      </div>
    </AppBar>
  );
}
