import { Button as MuiButton } from '@material-ui/core';
import styled from 'styled-components';

const GenericBtn = styled(({ font, fontSize, color, backgroundColor, borderColor, ...otherProps }) => <MuiButton {...otherProps} />)`
  font-family: ${props => props.font || '"Open Sans", sans-serif'};
  color: ${props => props.color || '#464946'};
  font-size: ${props => props.fontSize || '15px'} !important;
  background-color: ${props => props.backgroundColor || 'transparent'};
  border: ${props => props.borderColor ? `3px solid ${props.borderColor}` : 'none'};
  border-radius: 10px;
  padding: 8px 15px;
  width: 230px;
  text-transform: none;
  &:hover {
    background-color: ${props => props.backgroundColor || '#ffc103'};
    cursor: pointer;
  }

  @media (min-width: 600px) {
    font-size: ${props => props.fontSize + 2 || '16px'} !important;
  }
  @media (max-width: 900px) {
    font-weight: bold;
  }
  font-weight: bold;
  @media (min-width: 1400px) {
    font-size: ${props => props.fontSize + 4 || '18px'} !important;
  }
`;

export default GenericBtn;
