import { Button as MuiButton } from '@material-ui/core';
import styled from 'styled-components';

/**
 * Styling of an MUI Button to be used as a default button
 */
// const NavBtn = styled.button`
const NavBtn = styled(({ isActive, ...otherProps }) => <MuiButton {...otherProps} />)`
  font-family: "Open Sans", sans-serif;
  color: #464946;
  font-size: 21px !important;
  text-transform: none;
  font-weight: ${props => props.isActive ? 'bold' : 'normal'};
  @media (min-width: 920px) {
    font-size: 17px !important;
  }
  @media (min-width: 1100px) {
    font-size: 19px !important;
  }
`;

export default NavBtn;